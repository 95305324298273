<template>
  <div>
    <v-row>
      <v-col cols="grow">
        <v-card class="mb-4">
          <v-system-bar>MiSDV Art. 130 Halbtage</v-system-bar>

          <v-card-text>
            <ol>
              <li>
                Die Schülerinnen und Schüler sind berechtigt, an höchstens fünf
                Halbtagen pro Schuljahr dem Unterricht fernzubleiben.
              </li>
              <li>
                Die Halbtage können einzeln oder zusammenhängend bezogen werden.
              </li>
              <li>
                Der Bezug ist nicht zulässig an Halbtagen, an denen eine
                angekündigte schriftliche Prüfung oder eine schulische
                Sonderveranstaltung stattfindet oder an denen die Schülerin oder
                der Schüler einen geplanten Unterrichtsteil leisten muss.
              </li>
              <li>
                Der Bezug ist der Klassenlehrkraft sobald als möglich, jedoch
                spätestens zwei Tage im Voraus mitzuteilen.
              </li>
              <li>
                Ordnungsgemäss bezogene freie Halbtage gelten als entschuldigte
                Absenzen.
              </li>
            </ol>
          </v-card-text>
        </v-card>
        <v-card>
          <v-system-bar>MiSDV Art. 131 Absenzen</v-system-bar>

          <v-card-text>
            <ol>
              <li>
                Absenzen sind Abwesenheiten vom Unterricht, die nicht auf
                begründetes Gesuch hin vorgängig von der Schulleitung bewilligt
                werden, unabhängig davon, ob sie vorhersehbar sind.
              </li>
              <li>
                Sie sind der Klassenlehrkraft so bald als möglich zu melden.
                Spätestens innert acht Tagen nach Wiederaufnahme des Unterrichts
                sind Absenzen schriftlich der Klassenlehrkraft zu begründen.
              </li>
              <li>
                Sie gelten insbesondere bei Fehlen aus folgenden Gründen als
                entschuldigt:
                <ol type="a">
                  <li>Krankheit,</li>
                  <li>Unfall,</li>
                  <li>Arzt- oder Zahnarztbesuch,</li>
                  <li>Todesfall in der Familie.</li>
                </ol>
              </li>
              <li>
                In strittigen Fällen entscheidet die Schulleitung. Diese kann
                Arztzeugnisse oder andere Bestätigungen einfordern.
              </li>
              <li>
                Häufen sich bei unmündigen Schülerinnen oder Schülern Absenzen
                oder Verspätungen, nimmt die Klassenlehrkraft mit den Eltern
                Rücksprache.
              </li>
            </ol>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="shrink">
        <v-card>
          <v-system-bar>Dokumente Führungshandbuch</v-system-bar>
          <DocumentItemLoader :id="53001" />
          <DocumentItemLoader :id="201019" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DocumentItemLoader from "@/components/DocumentItemLoader.vue";
export default defineComponent({
  components: { DocumentItemLoader },
});
</script>
